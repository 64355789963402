import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { AddressForm } from './address-form.component'

export const AddressDialog = ({
  close,
  onSelect,
  cups,
  isIRC = false,
  onCancel = null,
  data = null,
  origen,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h5" color="textSecondary">
          {formatMessage({ id: 'pages.inspections.addAddressDialog.title' })}
        </Typography>
      </Box>
      <Box p={2} mt={4}>
        <AddressForm {...{ close, onSelect, cups, isIRC, onCancel, data, origen }} />
      </Box>
    </Box>
  )
}
