export const UPLOAD_BUTTONS_CONFIG = [
  'IRG1',
  'IRG2',
  'IRG3',
  'AI',
  'AC',
  // 'CC', Rite
  // 'CR',Solicitud de conexión de red
  'CH',
  'CPI',
  'DN',
  // 'SG',Solicitud de gas en fincas
  'AR',
  'CA',
  'EF',
  'CCEE',
  'CB',
  'EC',
  'SP',
  // 'FI', // tallo gestor
  // 'CI', // croquis gestor
  'tallo_i', // tallo instalador
  'croquis_i', // croquis instalador
  // 'FC1', // Otros
]
