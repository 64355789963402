export const ROOT_CERTIFICATES_FORM = 'pages.certificates.form'
export const ROOT_CERTIFICATES_DEVICES = 'pages.certificates.devices'
export const ROOT_CERTIFICATES_DOCUMENTS = 'pages.certificates.documents'
export const DEVICE_TYPES = [
  'calefaccion',
  'calentador',
  'cocina',
  'encimera',
  'secadora',
  'aparato_extra_1',
]
export const DEVICE_EXTS = ['_nombre', '_nuevos', '_transformar', '_marca', '_potencia']
export const DOCUMENT_TYPES = [
  'AI',
  'AR',
  'CA',
  'CC',
  'CH',
  'CI',
  'CM',
  'CP',
  'CR',
  'DN',
  // 'FC1',
  'FC2',
  'FC3',
  'SG',
  'CCEE',
]
export const DOCUMENT_EXTS = ['id', 'nombre', 'extension']
export const CERTIFICATES_FORM_CONFIG = ({ combos, intl, ...commonProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_FORM + '.' + extension })

  return {
    //Header:
    FECHA_CERTIFICADO: {
      fromData: (data) => ({
        value: data['fecha_certificado'],
      }),
      required: true,
      text_invalid: 'La fecha tiene que estar en formato dd/mm/AAAA',
      componentType: 'date',
      name: 'fecha_certificado',
      title: txt('header.fecha_certificado.title'),
      placeholder: txt('header.fecha_certificado.placeholder'),
      ...commonProps,
    },
    TIPO_MERCADO: {
      fromData: (data) => ({
        value: data['tipo_mercado'],
        values: combos['tipo_mercado'] ? combos['tipo_mercado'].data : [],
      }),
      required: true,
      text_invalid: 'Campo obligatorio',
      select: true,
      type: 'select',
      name: 'tipo_mercado',
      title: txt('header.tipo_mercado.title'),
      placeholder: txt('header.tipo_mercado.placeholder'),
      ...commonProps,
    },
    NUM_POLIZA: {
      fromData: (data) => ({
        value: data['numero_poliza'],
      }),
      name: 'numero_poliza',
      title: txt('header.numero_poliza.title'),
      placeholder: txt('header.numero_poliza.placeholder'),
      ...commonProps,
    },
    CODIGO_FINANCIACION: {
      fromData: (data) => ({
        value: data['codigo_financiacion'],
        hidden: !data.campanas?.['campana_financiacion'],
      }),
      name: 'codigo_financiacion',
      title: txt('header.codigo_financiacion.title'),
      text_invalid: 'El formato del código no es válido. Ejemplo: 202105880547041',
      ...commonProps,
    },
    CUPS: {
      fromData: (data) => ({
        value: data['cups'],
      }),
      name: 'cups',
      text_invalid: 'Campo obligatorio',
      title: txt('header.cups.title'),
      placeholder: txt('header.cups.placeholder'),
      ...commonProps,
    },
    //Empres)a
    EMPRESA: {
      fromData: (data) => ({
        value: data['empresa'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'empresa',
      title: txt('empresa.empresa.title'),
      ...commonProps,
    },
    NUM_REGISTRO: {
      fromData: (data) => ({
        value: data['numero_registro'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'numero_registro',
      title: txt('empresa.numero_registro.title'),
      ...commonProps,
    },
    TELF_EMPRESA: {
      fromData: (data) => ({
        value: data['telefono_movil_empresa'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'telefono_movil_empresa',
      title: txt('empresa.telefono_movil_empresa.title'),
      ...commonProps,
    },
    //Client
    NOMBRE_TITULAR: {
      fromData: (data) => ({
        value: data['nombre_titular'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'nombre_titular',
      title: txt('client.nombre_titular.title'),
      ...commonProps,
    },
    DOCUMENTO_TITULAR: {
      fromData: (data) => ({
        value: data['numero_documento_titular'],
        title:
          combos['tipo_documento'] && combos['tipo_documento'][data['tipo_documento_titular']]
            ? combos['tipo_documento'][data['tipo_documento_titular']]
            : txt('client.documento.title'),
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'numero_documento_titular',
      ...commonProps,
    },
    TELF_MOVIL_VISITA: {
      fromData: (data) => ({
        value: data['telefono_movil_visita'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'telefono_movil_visita',
      title: txt('client.telefono_movil_visita.title'),
      ...commonProps,
    },
    EMAIL_VISITA: {
      fromData: (data) => ({
        value: data['email_visita'],
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'email_visita',
      title: txt('client.email_visita.title'),
      ...commonProps,
    },
    ADDRESS: {
      fromData: (data) => ({
        value:
          `${data['via_emplazamiento']} ${data['numero_emplazamiento']}` +
          `${data['portal_emplazamiento'] ? '-' + data['portal_emplazamiento'] : ''} ` +
          `${data['escalera_emplazamiento'] ? '-' + data['escalera_emplazamiento'] : ''} ` +
          `${data['piso_emplazamiento']} ${data['puerta_emplazamiento']}`,
      }),
      type: 'text',
      componentType: 'read_only',
      name: 'address',
      title: txt('client.address_visita.title'),
      ...commonProps,
    },
    OBSERVACIONES: {
      fromData: (data) => ({
        value: data['observaciones'],
      }),
      multiline: true,
      name: 'observaciones',
      title: txt('observaciones.title'),
      placeholder: txt('observaciones.placeholder'),
      ...commonProps,
    },
  }
}

export const CERTIFICATES_DEVICE_CONFIG = ({ intl }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_DEVICES + '.' + extension })
  return {
    CALEFACCION: {
      id: 'calefaccion',
      label: txt('calefaccion.title'),
    },
    CALENTADOR: {
      id: 'calentador',
      label: txt('calentador.title'),
    },
    COCINA: {
      id: 'cocina',
      label: txt('cocina.title'),
    },
    ENCIMERA: {
      id: 'encimera',
      label: txt('encimera.title'),
    },
    SECADORA: {
      id: 'secadora',
      label: txt('secadora.title'),
    },
    EXTRA: {
      id: 'aparato_extra_1',
    },
  }
}

const GRID_ITEM_PROPS = {
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
}
const GRID_ITEM_QUADRUPLET = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
}
const GRID_ITEM_TRIPLET = {
  item: true,
  xs: 12,
  md: 4,
}

const CERTIFICATES_FORM_HEADER_STRUCTURE = [
  [
    { ref: 'FECHA_CERTIFICADO', conf: GRID_ITEM_PROPS },
    { ref: 'TIPO_MERCADO', conf: GRID_ITEM_PROPS },
    // { ref: 'NUM_POLIZA', conf: GRID_ITEM_PROPS },
    { ref: 'CUPS', conf: GRID_ITEM_PROPS },
    { ref: 'CODIGO_FINANCIACION', conf: GRID_ITEM_PROPS },
  ],
]
const CERTIFICATES_FORM_EMPRESA_STRUCTURE = [
  [
    { ref: 'EMPRESA', conf: GRID_ITEM_TRIPLET },
    { ref: 'NUM_REGISTRO', conf: GRID_ITEM_TRIPLET },
    { ref: 'TELF_EMPRESA', conf: GRID_ITEM_TRIPLET },
  ],
]
const CERTIFICATES_FORM_CLIENT_STRUCTURE = [
  [
    { ref: 'NOMBRE_TITULAR', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'DOCUMENTO_TITULAR', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'ADDRESS', conf: GRID_ITEM_QUADRUPLET },
    { ref: 'EMAIL_VISITA', conf: GRID_ITEM_QUADRUPLET },
  ],
  [{ ref: 'TELF_MOVIL_VISITA', conf: GRID_ITEM_PROPS }],
]

const CERTIFICATES_FORM_UPPER_PART = (txt) => [
  {
    foldable: false,
    items: CERTIFICATES_FORM_HEADER_STRUCTURE,
  },
  {
    title: txt('empresa.title'),
    items: CERTIFICATES_FORM_EMPRESA_STRUCTURE,
  },
  {
    title: txt('client.title'),
    items: CERTIFICATES_FORM_CLIENT_STRUCTURE,
  },
]

export const CERTIFICATES_FORM_STRUCTURE = ({
  intl,
  market,
  finantiation,
  applyCampaign,
  ingreso,
  subvencion,
}) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CERTIFICATES_FORM + '.' + extension })
  const defaultRequiredDocuments = [
    {
      field: 'd_IRG3_id',
      custom_validation: 'nonZero',
      // text_invalid: 'Este documento es obligatorio',
    },
    {
      field: 'fecha_certificado',
      custom_validation: 'required',
    },
    {
      field: 'cups',
      custom_validation: 'required',
    },
    {
      field: 'tipo_mercado',
      custom_validation: 'required',
    },
    {
      field: 'services',
      custom_validation: 'notEmpty',
    },
    {
      field: 'codigo_financiacion',
      custom_validation: 'regex',
      aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
    },
  ]
  const finantiationRequiredDocuments = {
    field: 'codigo_financiacion',
    custom_validation: 'mandatory',
    aux: /^20[23][0-9](1[0-2]|0[1-9])[0-9]{9}$/,
  }
  const transferRequiredFields = [
    {
      field: 'iban',
      custom_validation: 'mandatory',
      aux: /([a-zA-Z]{2})\s*\t*(\d{2})\s*\t*(\d{4})\s*\t*(\d{4})\s*\t*(\d{2})\s*\t*(\d{10})/,
    },
    {
      field: 'titular_iban',
      custom_validation: 'required',
    },
    {
      field: 'd_CB_id',
      custom_validation: 'nonZero',
    },
  ]

  const subvencionDocs = [
    {
      field: 'd_CPI_id',
      custom_validation: 'nonZero',
    },
  ]

  let requiredDocuments = defaultRequiredDocuments
  if (applyCampaign) {
    if (finantiation) {
      if (market === 'ES') {
        requiredDocuments = [...defaultRequiredDocuments, finantiationRequiredDocuments]
      }
      if (market === 'SV') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          finantiationRequiredDocuments,

          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_CPI_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_CB_id',
            custom_validation: 'nonZero',
          },
        ]
      }
      if (market === 'PYMES') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          finantiationRequiredDocuments,

          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_CB_id',
            custom_validation: 'nonZero',
          },
        ]
      }
      if (market === 'SH' || market === 'SP') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          finantiationRequiredDocuments,

          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
        ]
      }

      if (market === 'CC') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          finantiationRequiredDocuments,

          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_CPI_id',
            custom_validation: 'nonZero',
          },
        ]
      }
    } else if (!finantiation) {
      if (market === 'ES') {
        requiredDocuments = [...defaultRequiredDocuments]
        if (ingreso) {
          requiredDocuments = [...transferRequiredFields, ...defaultRequiredDocuments]
        }
      }
      if (market === 'SV') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
        ]
        if (ingreso) {
          requiredDocuments = [...requiredDocuments, ...transferRequiredFields]
        }
        if (subvencion && !ingreso) {
          requiredDocuments = [...requiredDocuments, ...subvencionDocs]
        }
        if (subvencion && ingreso) {
          requiredDocuments = [...requiredDocuments, ...transferRequiredFields, ...subvencionDocs]
        }
      }
      if (market === 'PYMES') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
        ]
        if (ingreso) {
          requiredDocuments = [...requiredDocuments, ...transferRequiredFields]
        }
      }
      if (market === 'SH' || market === 'SP') {
        requiredDocuments = [
          ...defaultRequiredDocuments,
          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
        ]
        if (ingreso) {
          requiredDocuments = [...requiredDocuments, ...transferRequiredFields]
        }
      }

      if (market === 'CC') {
        requiredDocuments = [
          ...defaultRequiredDocuments,

          {
            field: 'd_CCEE_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_DN_id',
            custom_validation: 'nonZero',
          },
          {
            field: 'd_CPI_id',
            custom_validation: 'nonZero',
          },
        ]
      }
    }
  }

  return {
    '03': {
      label: txt('irg3'),
      structure: [...CERTIFICATES_FORM_UPPER_PART(txt)],
      documents: [
        'IRG3',
        'DN' /*documento identidad 2 caras*/,
        'AI' /*Autorizacio interrupcion gas*/,
        'SP' /*servidumbre paso*/,
        'EC' /*Escrituras constitucion*/,
        'AC' /*Acuerdo comunidad*/,
        'CA' /*correo autorizacion*/,
        'CCEE' /*condiciones economicas*/,
        'CPI' /*copia presupuesto instalación*/,
        'CB' /*comprobante cuenta bancaria*/,
        'FC1' /*Otros*/,
      ],
      devices: ['CALEFACCION', 'CALENTADOR', 'COCINA', 'ENCIMERA', 'SECADORA', 'EXTRA'],
      services: true,
      validation: requiredDocuments,
      disclaimer: txt('check.irg'),
      endpoint: '/certificado_IRG3',
    },
  }
}
