import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useInspectionsCalls = () => {
  const { formatMessage } = useIntl()
  const { post, put, get, putById } = requests
  const global = useSelector((state) => state.global)

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const ipPost = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio ? '/inspecciones/gremio' : '/inspecciones'
        post(endpoint, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const ipPut = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/inspecciones', id, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jcaPost = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio ? '/jca/gremio' : '/jca'
        post(endpoint, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jcaPut = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio ? '/jca/gremio' : '/jca'
        putById(endpoint, id, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const homeInfoGetCall = useCallback(
    (cups) =>
      new Promise((resolve, reject) => {
        get(`/puntos_suministro/find_by_cups/${cups}`, { timeout: 0 })
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.response.status) {
              case 400:
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const fetchInspectors = useCallback(
    (companyId) =>
      new Promise((resolve, reject) => {
        const endpoint = companyId
          ? `inspectores/inspectores_empresa/${companyId}`
          : '/inspectores/inspectores'
        get(endpoint)
          .then((response) => resolve(response))
          .catch((response) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.response.status) {
              case 400:
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const ipNonConformityCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio
          ? '/inspecciones/gremio_no_conformidad_datos'
          : '/inspecciones/no_conformidad_datos'
        post(endpoint, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jcaNonConformityCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio
          ? '/jca/gremio_no_conformidad_datos'
          : '/jca/no_conformidad_datos'
        post(endpoint, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const jcaValidationCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const endpoint = !!global.gremio
          ? '/jca/gremio_check_jca_validations'
          : '/jca/check_jca_validations'
        put(endpoint, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const associatedCompaniesCall = useCallback(
    (guildId, payload) =>
      new Promise((resolve, reject) => {
        get(`/gremios/${guildId}/empresas_ip_asociadas`, payload)
          .then((response) => resolve(response))
          .catch((response) => reject(response))
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getComercializadoras = async (cp, origen = 'zeus') => {
    return await new Promise((resolve, reject) => {
      get(`/puntos_suministro/comercializadoras/${cp}?origen=${origen}`, { timeout: 0 })
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }

  return {
    ipPost,
    ipPut,
    jcaPost,
    jcaPut,
    homeInfoGetCall,
    fetchInspectors,
    ipNonConformityCall,
    jcaNonConformityCall,
    jcaValidationCall,
    associatedCompaniesCall,
    getComercializadoras,
  }
}
