import packageJson from '../../../../../package.json'

export const APP_VERSION_MSSG = `Version ${packageJson.version} (${process.env.REACT_APP_ENVIRONMENT})`

const DOCUMENTS_URL = 'https://www.nedgia.es/colaboradores/documentos-a-presentar/'

const IP3_GUIDE_DOC_URL =
  'https://www.nedgia.es/wp-content/uploads/2024/05/Guia-Explicativa-EEII-Nueva-Web_Gremios-v1.0.pdf'

const EXISTING_CONNECTIONS_REQUEST_URL = 'https://www.nedgia.es/conexiones-y-actuaciones-en-la-red/'
// 'https://www.nedgia.es/clientes/servicios-de-gas-natural/hogar/actuaciones-en-acometidas-existentes/'

const SUBMENU_LINKS_INSTALLER = [
  { id: 'url1' },
  { id: 'url2' },
  //{ id: 'url4' },
  { id: 'offers' },
  { id: 'private_area_navigation_guide' },
  { id: 'corporative_identity' },
  { id: 'otras_aplicaciones_nedgia' },
]

const SUBMENU_LINKS = [
  { id: 'url1' },
  { id: 'url2' },
  { id: 'offers' },
  { id: 'private_area_navigation_guide' },
  { id: 'corporative_identity' },
  { id: 'otras_aplicaciones_nedgia' },
]

export const MENU_INSTALLER_C = [
  { id: 'folder1', children: [{ id: 'consult' }, { id: 'consult2' }] },
  { id: 'applications' },
  { id: 'tariffs' },
  { id: 'folder2', children: SUBMENU_LINKS_INSTALLER },
  { id: 'users' },
]

export const MENU_INSTALLER_IP = [
  { id: 'users' },
  { id: 'ip' },
  { id: 'jca' },
  { id: 'folder2', children: [{ id: 'url3' }] },
]

export const MENU_INSTALLER_C_IP = [
  { id: 'folder1', children: [{ id: 'consult' }, { id: 'consult2' }] },
  { id: 'applications' },
  { id: 'tariffs' },
  { id: 'folder2', children: SUBMENU_LINKS_INSTALLER },
  { id: 'users' },
  { id: 'folder4', children: [{ id: 'ip' }, { id: 'jca' }, { id: 'url3' }] },
]

export const MENU_GUILD_C = [
  { id: 'folder1', children: [{ id: 'consult' }, { id: 'consult2' }] },
  { id: 'applications' },
  { id: 'folder2', children: SUBMENU_LINKS },
  { id: 'folder3', children: [{ id: 'associates' }, { id: 'guildUsers' }] },
]

export const MENU_GUILD_IP = [
  { id: 'ip' },
  { id: 'jca' },
  { id: 'folder2', children: [{ id: 'url3' }] },
  { id: 'folder3', children: [{ id: 'associates_ip3' }, { id: 'guildUsers' }] },
]

export const MENU_GUILD_C_IP = [
  { id: 'folder1', children: [{ id: 'consult' }, { id: 'consult2' }] },
  { id: 'applications' },
  { id: 'folder2', children: SUBMENU_LINKS },
  {
    id: 'folder3',
    children: [{ id: 'associates' }, { id: 'associates_ip3' }, { id: 'guildUsers' }],
  },
  {
    id: 'folder4',
    children: [{ id: 'ip' }, { id: 'jca' }, { id: 'url3' }],
  },
]

export const MENU_MARKETER = [{ id: 'marketerUsers' }, { id: 'marketerPotential' }]

export const PROFILE_ROUTE = '/profile'

export const MENU_ITEMS = {
  url1: {
    id: 'url1',
    url: DOCUMENTS_URL,
  },
  url2: {
    id: 'url2',
    url: EXISTING_CONNECTIONS_REQUEST_URL,
  },
  url3: {
    id: 'url3',
    url: IP3_GUIDE_DOC_URL,
  },
  url4: {
    id: 'url4',
    url: 'wordpress_url',
  },
  folder1: {
    id: 'folder1',
  },
  folder2: {
    id: 'folder2',
  },
  folder3: {
    id: 'folder3',
  },
  folder4: {
    id: 'folder4',
  },
  offers: {
    id: 'offers',
    path: '/offers',
  },
  private_area_navigation_guide: {
    id: 'private_area_navigation_guide',
    path: '/private-area-navigation-guide',
  },
  corporative_identity: {
    id: 'corporative_identity',
    path: '/corporative-identity',
  },
  consult: {
    id: 'consult',
    path: '/consult',
  },
  otras_aplicaciones_nedgia: {
    id: 'otras_aplicaciones_nedgia',
    path: '/otherApps',
  },
  consult2: {
    id: 'consult2',
    path: '/consult2',
  },
  applications: {
    id: 'applications',
    path: '/applications',
  },
  tariffs: {
    id: 'tariffs',
    path: '/tariffs',
  },
  users: {
    id: 'users',
    path: '/users',
  },
  ip: {
    id: 'ip',
    path: '/ip',
  },
  jca: {
    id: 'jca',
    path: '/jca',
  },
  associates: {
    id: 'associates',
    path: '/associates',
  },
  associates_ip3: {
    id: 'associates_ip3',
    path: '/associates-ip3',
  },
  guildUsers: {
    id: 'guildUsers',
    path: '/guildUsers',
  },
  marketerUsers: {
    id: 'marketerUsers',
    path: '/marketerUsers',
  },
  marketerPotential: {
    id: 'marketerPotential',
    path: '/marketerPotential',
  },
}
